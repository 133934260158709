<template>
	<div>
		<van-pull-refresh v-model="refreshing" @refresh="onRefresh" :style="{ 'min-height': 'calc(100vh - ' + headerHeight + ' )' }">
			<van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
				<div class="list">
					<template v-for="(item, index) in list">
						<div class="row cb item" v-if="tabValue == 0">
							<div class="row left">
								<div class="col info">
									<div class="title text-ellipsis">{{ item.name }}</div>
									<div class="desc text-ellipsis">{{ item.describe }}</div>
								</div>
							</div>
							<div class="right">
								<div class="row cc btn">{{ item.value }}积分</div>
							</div>
						</div>
						<div class="row cb item" v-else-if="tabValue == 1">
							<div class="row left">
								<van-image class="avatar" :src="$store.getters.oss(item.image)" fit="cover" round />
								<div class="col info">
									<div class="title text-ellipsis">{{ item.goods_name }}</div>
									<div class="desc text-ellipsis">{{ item.introduce }}</div>
								</div>
							</div>
							<div class="right">
								<div class="row cc btn" @click="confirmExchange(index)">{{ item.price }}积分</div>
							</div>
						</div>
						<div class="row cb item" v-else>
							<div class="row left">
								<div class="col info">
									<div class="title text-ellipsis">{{ item.behavior }}</div>
									<div class="desc text-ellipsis">{{ item.create_time_text }}</div>
								</div>
							</div>
							<div class="right">
								<div class="row cc num">{{ item.type == 0 ? '-' : '+' }}{{ item.change_point }}积分</div>
							</div>
						</div>
					</template>
				</div>
			</van-list>
		</van-pull-refresh>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import { Toast } from 'vant';
export default {
	props: {
		headerHeight: {
			type: String,
			default: ''
		},
		tabValue: {
			type: Number,
			default: 0
		}
	},
	computed: {},
	data() {
		return {
			page: 0,
			pageSize: 20,
			list: [],
			loading: false,
			finished: false,
			refreshing: false
		};
	},
	methods: {
		confirmExchange(index) {
			let item = this.list[index];
			this.$dialog
				.confirm({
					title: '提示',
					message: '您确认要兑换？'
				})
				.then(async () => {
					await this.$service.post('point/pointExchange', { goods_id: item.id });
					Toast.success({
						message: '兑换成功'
					});
					this.onRefresh();
					this.$emit('reload');
				})
				.catch(() => {});
		},
		onLoad() {
			if (this.refreshing) {
				this.list = [];
				this.refreshing = false;
			} else {
				console.log('onLoad');
				this.loading = true;
				this.page++;
				let url = '';
				if (this.tabValue == 0) {
					url = 'point/pointRuleIndex';
				} else if (this.tabValue == 1) {
					url = 'point/pointMallIndex';
				} else {
					url = 'point/pointLog';
				}
				this.$service
					.post(url, {
						page: this.page,
						pageSize: this.pageSize
					})
					.then(res => {
						this.list = this.list.concat(res.data.data);
						this.loading = false;
						if (res.data.data.length < this.pageSize) {
							this.finished = true;
						}
					});
			}
		},
		onRefresh() {
			console.log('onRefresh');
			this.page = 0;
			this.finished = false;
			this.refreshing = true;
			this.onLoad();
		}
	}
};
</script>

<style scoped lang="less">
.list {
	background-color: #ffffff;

	.item {
		border-bottom: 1px solid #f5f5f5;
		height: 1.92rem;
		padding: 0 0.48rem;
		box-sizing: border-box;
		.left {
			flex: 1;
			.avatar {
				width: 1.226666rem;
				height: 1.226666rem;
				border-radius: 50%;
			}
			.info {
				margin-left: 0.16rem;
				flex: 1;
				height: 1.226666rem;
				overflow: hidden;
				justify-content: space-around;
				.title {
					color: #333333;
					font-size: 0.373333rem;
					font-weight: bold;
				}
				.desc {
					color: #999999;
					font-size: 0.266666rem;
				}
			}
		}
		.right {
			.num {
				color: #666666;
				font-size: 0.373333rem;
			}
			.btn {
				height: 0.64rem;
				color: #ffffff;
				font-size: 0.32rem;
				padding: 0 0.213333rem;
				box-sizing: border-box;
				background-color: @green;
				border-radius: 0.32rem;
			}
		}
	}
}
</style>
