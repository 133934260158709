<template>
	<div>
		<div class="header" ref="header">
			<van-image class="banner-img" :src="require('@/assets/integral_banner.png')" fit="cover" />
			<div class="row cc rule" @click="navRule()">活动规则</div>
			<div class="col left">
				<div class="col current">
					<span class="label">当前积分</span>
					<span class="value">{{ userInfo.point }}</span>
				</div>
				<div class="col desc">
					<span>杏田问答平台内积分，可用于参与问题互动，</span>
					<span>或积分兑换</span>
				</div>
			</div>
		</div>
		<div class="tabs" ref="tabs">
			<van-tabs v-model="tabValue" :color="'#03D3BC'" :line-width="'16px'" :line-height="'4px'" animated sticky swipeable lazy-render :ellipsis="false">
				<van-tab v-for="(title, index) in tabs" :title="title">
					<div><listData :ref="'listData' + index" ref="listData" :headerHeight="headerHeight" :tabValue="index" @reload="getUserInfo()"></listData></div>
				</van-tab>
			</van-tabs>
		</div>
	</div>
</template>

<script>
import listData from './components/list.vue';
export default {
	data() {
		return {
			tabs: ['活动', '兑换', '记录'],
			tabValue: 0,
			headerHeight: '',
			userInfo: {}
		};
	},
	components: { listData },
	mounted() {
		this.headerHeight = this.$refs['header'].offsetHeight + this.$refs['tabs'].offsetHeight + 'px';
	},
	created() {
		this.getUserInfo();
	},
	methods: {
		navRule() {
			this.$router.push({
				path: '/integral/rule'
			});
		},
		async getUserInfo() {
			let userInfo = await this.$service.post('user_info/detail', {});
			this.userInfo = userInfo.data;
		}
	}
};
</script>

<style scoped lang="less">
@import './integral.less';
</style>
